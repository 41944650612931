import React, { useEffect } from 'react';

const SubstackFeed = () => {
  useEffect(() => {
    // Set the Substack feed configuration
    window.SubstackFeedWidget = {
      substackUrl: "hasitakrishna.substack.com",
      posts: 8
    };

    // Create the script element
    const script = document.createElement("script");
    script.src = "https://substackapi.com/embeds/feed.js";
    script.async = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="substack-feed-embed"></div>;
};

export default SubstackFeed;