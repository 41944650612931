import "./DotTitle.scss";
import { motion } from "framer-motion";

const DotTitle = ({ title }) => {
  return (
    <div className="dot_title">
      <div className="circle_wrap">
        <motion.span
          initial={{ translateX: 24, opacity: 0.4 }}
          whileInView={{ translateX: 0, opacity: 1 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
          viewport={{ once: true }}
          className="dark"
        ></motion.span>
        <motion.span
          initial={{ translateX: 24, opacity: 0 }}
          whileInView={{ translateX: 0, opacity: 1 }}
          transition={{ duration: 0.6, ease: "easeInOut", delay: 0.1 }}
          viewport={{ once: true }}
          className="light"
        ></motion.span>
      </div>
      <motion.p
        initial={{ translateX: 24, opacity: 0 }}
        whileInView={{ translateX: 0, opacity: 1 }}
        transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
        viewport={{ once: true }}
      >
        {title}
      </motion.p>
    </div>
  );
};

export default DotTitle;
