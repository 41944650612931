import "./SecTitle.scss";
import DotTitle from "../dotTitle/DotTitle";
import { motion } from "framer-motion";

const SecTitle = ({title, mainTitle, theme}) => {
    return(
        <div className={`sec_title ${theme}`}>
        <div className="title_side">
          <DotTitle title={title} />
        </div>
        <div className="right">
          <motion.h2
            initial={{ translateY: 24, opacity: 0 }}
            whileInView={{ translateY: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            viewport={{ once: true }}
          >
        {mainTitle}
          </motion.h2>
       
        </div>
      </div>
    )
}

export default SecTitle;