import { images } from "../../utils/images";
import "./PreLoader.scss";
import { motion } from "framer-motion";

const PreLoader = () => {
  return (
    <div className="pre_loader">
      <div className="loader_content">
        <motion.img
         initial={{ scale:0.5, opacity: 0 }}
         whileInView={{ scale:1, opacity: 1 }}
         transition={{ duration: 0.6, ease: "easeInOut", delay: 2.5 }}
        src={images.logo} alt="Brand Logo" />

        <div className="name">
          <motion.h2
            initial={{ translateX: -48, opacity: 0 }}
            whileInView={{ translateX: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 2.5 }}
          >
            Motley
          </motion.h2>

          <motion.h2
            initial={{ translateX: 48, opacity: 0 }}
            whileInView={{ translateX: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 2.5 }}
          >
            &nbsp;Crew
          </motion.h2>
        </div>
      </div>

      <div className="box 1" style={{ animationDelay: "0.5s" }}></div>
      <div className="box 2" style={{ animationDelay: "0.6s" }}></div>
      <div className="box 3" style={{ animationDelay: "0.7s" }}></div>
      <div className="box 4" style={{ animationDelay: "0.8s" }}></div>

      <div className="box 12" style={{ animationDelay: "1.6s" }}></div>
      <div className="box c 13" style={{ animationDelay: "1.7s" }}></div>
      <div className="box c 14" style={{ animationDelay: "1.8s" }}></div>
      <div className="box 5" style={{ animationDelay: "0.9s" }}></div>

      <div className="box 11" style={{ animationDelay: "1.5s" }}></div>
      <div className="box c 16" style={{ animationDelay: "2s" }}></div>
      <div className="box c 15" style={{ animationDelay: "1.9s" }}></div>
      <div className="box 6" style={{ animationDelay: "1s" }}></div>

      <div className="box 10" style={{ animationDelay: "1.4s" }}></div>
      <div className="box 9" style={{ animationDelay: "1.3s" }}></div>
      <div className="box 8" style={{ animationDelay: "1.2s" }}></div>
      <div className="box 7" style={{ animationDelay: "1.1s" }}></div>
    </div>
  )
}

export default PreLoader;
