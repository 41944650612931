import "./HomeLanding.scss";
import SquareBtn from "../../../components/squareBtn/SquareBtn";
import { motion } from "framer-motion";

const HomeLanding = ({ homeData }) => { 

  const goTo = () => {
    document.getElementById(`work`).scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <section className="home_landing">
      <div className="content_wrap">
        <div className="text">
          <motion.h1
            initial={{ translateY: 24, opacity: 0 }}
            whileInView={{ translateY: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            viewport={{ once: true }}
          >
            {homeData?.main_title || "-"}
          </motion.h1>
          <motion.p
            initial={{ translateY: 24, opacity: 0 }}
            whileInView={{ translateY: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.1 }}
            viewport={{ once: true }}
          >
            {homeData?.main_description || "-"}
          </motion.p>
          <motion.div
            initial={{ translateY: 24, opacity: 0 }}
            whileInView={{ translateY: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
            viewport={{ once: true }}
          >
            <SquareBtn title="Let’s Find Out" onClick={goTo} />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default HomeLanding;
