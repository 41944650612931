import "./ErrorPage.scss";
import WebLayout from "../../layouts/weblayout/WebLayout";

const ErrorPage = () => {
  return (
    <WebLayout isCta={false}>
      <section className="error_page p_t ">
        <h1>
          THIS <span>PAGE</span> <span>WAS</span> <span>NOT</span>{" "}
          <span>FOUND</span>
        </h1>
        <h1>
          <span>THIS</span> <span>PAGE</span> WAS <span>NOT</span>{" "}
          <span>FOUND</span>
        </h1>
        <h1>
          <span>THIS</span> PAGE <span>WAS</span> <span>NOT</span>{" "}
          <span>FOUND</span>
        </h1>
        <h1>
          <span>THIS</span> <span>PAGE</span> <span>WAS</span> <span>NOT</span>{" "}
          FOUND
        </h1>
        <h1>
          <span>THIS</span> <span>PAGE</span> <span>WAS</span> NOT{" "}
          <span>FOUND</span>
        </h1>
        <h1>
          <span>THIS</span> <span>PAGE</span> <span>WAS</span> <span>NOT</span>{" "}
          <span>FOUND</span>
        </h1>
      </section>
    </WebLayout>
  );
};

export default ErrorPage;
