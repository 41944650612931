import "./AboutLanding.scss";
import { Parallax } from "react-scroll-parallax";

import { Fragment } from "react";
import useScrollPosition from "../../../utils/hooks/useScroll";
import { IoIosArrowRoundDown } from "react-icons/io";
import { baseUrl } from "../../../utils/apiData";

const AboutLanding = ({ data }) => {
  const goTo = (secId) => {
    const section = document.getElementById(secId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <section className="about_landing p_t p_b">
      <div className="content_wrap">
        <div className="content_slide">
          <div className="left_text">
            <Parallax speed={15}>
              <h1>{data?.title_1 || "-"}</h1>
            </Parallax>
          </div>

          <div className="img_box">
            <img src={`${baseUrl}/${data?.video}`} alt="About" />
          </div>

          <div className="right_text">
            <Parallax speed={5}>
              <h1>{data?.title_2 || "-"}</h1>
            </Parallax>
          </div>
        </div>

        <div className="bottom_line">
    
          <div className="des" dangerouslySetInnerHTML={{ __html: data?.main_description }} />
    

          <div className="right">
            <button type="button" onClick={() => goTo("values")}>
              <IoIosArrowRoundDown />
              Values
            </button>
            <button type="button" onClick={() => goTo("team")}>
              <IoIosArrowRoundDown />
              Team
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutLanding;
