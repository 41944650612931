import "./Cta.scss";
import { images } from "../../../utils/images";
import SquareBtn from "../../../components/squareBtn/SquareBtn";
import { motion } from "framer-motion";
import { useContext, useEffect } from "react";
import { dataContext } from "../../../webContext/WebContext";
import {  useNavigate } from "react-router-dom";
import { baseUrl } from "../../../utils/apiData";

const Cta = ({page}) => {
const navigate = useNavigate();
  const {getCtaApi, cta} = useContext(dataContext);

  useEffect(() => {
    getCtaApi(page)
  }, [])

  return (
    <section className="cta">
      <div className="content_wrap">
        <motion.div
         initial={{ translateY:24,scaleX:1.1, opacity: 0 }}
         whileInView={{ translateY: 0,scaleX:1, opacity: 1 }}
         transition={{ duration: 0.6, ease: "easeInOut", delay:0.2 }}
         viewport={{ once: true }}
        className="home_cta_wrap">
          <div className="overlay"></div>
          <img src={`${baseUrl}/${cta?.image}`} alt="Bg" />
          <div className="cta_content">
            <div className="dot_line">
              <div className="circle_wrap">
                <span className="dark"></span>
                <span className="light"></span>
              </div>
              <p>{cta?.heading}</p>
            </div>
            <h2>{cta?.title}</h2>
            <p className="detail">
           {cta?.description}
            </p>
            <SquareBtn theme="light" title="Contact Us" onClick={() => navigate('/contact')} />
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Cta;
