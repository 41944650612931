import "./Team.scss";
import SecTitle from "../../../components/secTitle/SecTitle";
import { motion } from "framer-motion";
import { Fragment } from "react";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { baseUrl } from "../../../utils/apiData";

const Team = ({ team, title, tag }) => {
  return (
    <section className="team p_t p_b" id="team">
      <div className="content_wrap">
        <SecTitle title={tag} mainTitle={title} />

        <div className="team_grid">
          {team.map((data, j) => {
            return (
              <Fragment key={data._id}>
                <motion.div
                  initial={{ translateY: 24, opacity: 0 }}
                  whileInView={{ translateY: 0, opacity: 1 }}
                  transition={{
                    duration: 0.3,
                    ease: "easeOut",
                    delay: 0.1 * (j % 3),
                  }}
                  viewport={{ once: true }}
                  className="team_card"
                >
                  <div className="img_wrap">
                    <div className="overlay"></div>
                    <img src={`${baseUrl}/${data?.image}`} alt="Team" />
                  </div>
                  <div className="text_content">
                    <h3>{data.name}</h3>
                    <p>{data?.proffession}</p>
                    <div className="social">
                      {data?.linkedin_link && (
                        <a href={data?.linkedin_link} target="_blank">
                          <FaLinkedinIn />
                        </a>
                      )}
                      {data?.instagram_link && (
                        <a href={data?.instagram_link} target="_blank">
                          <FaInstagram />
                        </a>
                      )}
                    </div>
                  </div>
                </motion.div>
              </Fragment>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Team;
