import { useContext } from "react";
import { dataContext } from "../../../webContext/WebContext";
import "./TagLine.scss";
import Marquee from "react-fast-marquee";

const TagLine = () => {
  const {cta} = useContext(dataContext);

const title = cta?.footer_text || "-"

  return (
    <div className="tag_line">
      <Marquee>
        <div className="text_slide">
        <h1 className="tag">A BETTER NARRATIVE BEGINS HERE</h1>
        </div>
        <div className="text_slide">
        <h1 className="tag">A BETTER NARRATIVE BEGINS HERE</h1>
        </div>
      </Marquee>
    </div>
  );
};
export default TagLine;
