import { Fragment } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Cta from "../../pages/home/cta/Cta";
import { images } from "../../utils/images";

const WebLayout = ({ children, page , isCta}) => {
  return (
    <Fragment>      
      <Header />
      {children}
     {isCta && <Cta page={page} />}
      <Footer />
    </Fragment>
  );
};

export default WebLayout;
