import "./CaseLanding.scss";
import SquareBtn from "../../../components/squareBtn/SquareBtn";
import { MdArrowBack } from "react-icons/md";
import useScrollPosition from "../../../utils/hooks/useScroll";
import SecTitle from "../../../components/secTitle/SecTitle";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../utils/apiData";

const CaseLanding = ({ data }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const navigate = useNavigate();
  const scrollToSection = () => {
    const section = document.getElementById("secimg");

    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const scroll = useScrollPosition();

  return (
    <section className="case_section p_t p_b">
      <div className="content_wrap">
        {/* Content */}
        <div className="bi_grid">
          <div className="name">
            <p className="pb-5">{formatDate(data?.publish_date)}</p>
            <motion.h1
              initial={{ translateY: 24, opacity: 0 }}
              whileInView={{ translateY: 0, opacity: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              viewport={{ once: true }}
            >
              {data?.name}
            </motion.h1>
            <motion.h4
              initial={{ translateY: 24, opacity: 0 }}
              whileInView={{ translateY: 0, opacity: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
              viewport={{ once: true }}
            >
              {data?.subtitle}
            </motion.h4>
          </div>
          <div className="img_wrap">
            <motion.img
              initial={{ translateX: 48, opacity: 0 }}
              whileInView={{ translateX: 0, opacity: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              viewport={{ once: true }}
              src={`${baseUrl}/${data?.image}`}
              alt="Case Study"
            />
          </div>
        </div>
        {/* Btns */}
        <motion.div
          initial={{ translateY: 24, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
          viewport={{ once: true }}
          className="btns_wrap"
        >
          <div className="left">
            {data?.website && (
              <a href={data?.website} target="_blank">
                <SquareBtn title="Visit Website" />
              </a>
            )}

            {scroll < 20 && (
              <button className="scroll" onClick={scrollToSection}>
                <div className="spine"></div>
                <p>Scroll for more</p>
              </button>
            )}
          </div>
          <div className="right">
            {data?.tags?.map((pill) => {
              return (
                <div className="pill" key={pill?._id}>
                  <p>{pill.value}</p>
                </div>
              );
            })}
          </div>
        </motion.div>
        {/* Social */}
        <motion.div
          initial={{ translateY: 24, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
          viewport={{ once: true }}
          className="social_line"
        >
          <button className="redirect" onClick={() => navigate("/work")}>
            <span>
              <MdArrowBack />
            </span>
            All Case Studies
          </button>
        </motion.div>

        {(data?.content_image_1 || data?.youtube_link) && (
          <motion.div
            initial={{ translateY: 24, opacity: 0 }}
            whileInView={{ translateY: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            viewport={{ once: true }}
            className="sec_img p_t"
            id="secimg"
          >
            {data?.content_type === "youtube" ? (
              <iframe
                src={`${data?.youtube_link}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            ) : (
              <img
                src={`${baseUrl}/${data?.content_image_1}`}
                alt="Case Study"
              />
            )}
          </motion.div>
        )}

        <div className="brief p_t">
          <SecTitle title="Brief" mainTitle={data?.section_1_title} />

          <motion.div
            initial={{ translateY: 24, opacity: 0 }}
            whileInView={{ translateY: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
            viewport={{ once: true }}
            dangerouslySetInnerHTML={{ __html: data?.section_1_content }}
          ></motion.div>
        </div>

        {(data?.section_1_image_1 ||
          data?.section_1_image_2 ||
          data?.section_1_youtube_1 ||
          data?.section_1_youtube_2) && (
          <div className="bi_img p_t">
            {(data?.section_1_image_1 || data?.section_1_youtube_1) &&
              (data?.section_1_format_1 === "youtubelink" ? (
                <div className="u_tube">
                  <iframe
                    src={`${data?.section_1_youtube_1}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                  />
                </div>
              ) : (
                <div className="img_wrap">
                  <motion.img
                    initial={{ translateX: -48, opacity: 0 }}
                    whileInView={{ translateX: 0, opacity: 1 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                    viewport={{ once: true }}
                    src={`${baseUrl}/${data?.section_1_image_1}`}
                    alt="Case Study"
                  />
                </div>
              ))}
            {(data?.section_1_image_2 || data?.section_1_youtube_2) &&
              (data?.section_1_format_2 === "youtubelink" ? (
                <div className="u_tube">
                  <iframe
                    src={`${data?.section_1_youtube_2}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                  />
                </div>
              ) : (
                <div className="img_wrap">
                  <motion.img
                    initial={{ translateX: 48, opacity: 0 }}
                    whileInView={{ translateX: 0, opacity: 1 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                    viewport={{ once: true }}
                    src={`${baseUrl}/${data?.section_1_image_2}`}
                    alt="Case Study"
                  />
                </div>
              ))}
          </div>
        )}
        <motion.div
          initial={{ translateY: 24, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
          viewport={{ once: true }}
          className="mt-5"
          dangerouslySetInnerHTML={{ __html: data?.section_2_content }}
        ></motion.div>
      </div>
    </section>
  );
};

export default CaseLanding;
