import "./LogoSlider.scss";
import SecTitle from "../../../components/secTitle/SecTitle";
import Marquee from "react-fast-marquee";
import axios from "axios";
import { baseUrl, getClient } from "../../../utils/apiData";
import { useEffect, useState } from "react";

const LogoSlider = () => {
  const [clients, setClients] = useState([]);
  const getClientsApi = async () => {
    try {
      const res = await axios.get(`${baseUrl}/${getClient}`);
      if (res?.data?.success) {
        setClients(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClientsApi();
  }, []);

  return (
    <section className="logo_sec p_b">
      <div className="content_wrap">
        <SecTitle
          title="Our Clients"
          mainTitle="Brands we have worked with"
          theme="dark"
        />
        {clients?.length > 0 ? (
          <Marquee>
            {clients?.map((client) => {
              return (
                <div className="logo_slide" key={client?._id}>
                  <div className="logo_wraper">
                    <img src={`${baseUrl}/${client?.image}`} alt="Logo" />
                  </div>
                </div>
              );
            })}
          </Marquee>
        ) : (
          <p className="text-center text-white">Data not found...</p>
        )}
      </div>
    </section>
  );
};

export default LogoSlider;
