import { Fragment } from "react";
import DotTitle from "../../../components/dotTitle/DotTitle";
import "./Faq.scss";
import Accordion from "react-bootstrap/Accordion";
import { IoMdAdd } from "react-icons/io";
import { IoMdRemove } from "react-icons/io";
import { motion } from "framer-motion";

const Faq = ({homeData, data}) => {

  return (
    <section className="faq p_b p_t">
      <div className="content_wrap">
        <div className="left_title">
          <DotTitle title={homeData?.faq_heading || "-"} />
          <motion.h2
            initial={{ translateY: 24, opacity: 0 }}
            whileInView={{ translateY: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
            viewport={{ once: true }}
          >
           {homeData?.faq_title || "-"}
          </motion.h2>
        
        </div>
        <div className="faq_accord">
          <Accordion defaultActiveKey="0">
            {data?.map((faq, j) => {
              return (
                <Fragment key={faq._id}>
                  <motion.div
                    initial={{ translateY: 24, opacity: 0 }}
                    whileInView={{ translateY: 0, opacity: 1 }}
                    transition={{
                      duration: 0.4,
                      ease: "easeInOut",
                      delay: 0.1 * j,
                    }}
                    viewport={{ once: true }}
                  >
                    <Accordion.Item eventKey={`${j}`}>
                      <Accordion.Header>
                        <h5>{faq.faq_title || "-"}</h5>
                        <div className="circle">
                          <span className="open">
                            <IoMdAdd />
                          </span>
                          <span className="close">
                            <IoMdRemove />
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                      <div
                      className="desc_text"
      dangerouslySetInnerHTML={{__html: faq?.faq_description}}
    />
                       
                      </Accordion.Body>
                    </Accordion.Item>
                  </motion.div>
                </Fragment>
              );
            })}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default Faq;
