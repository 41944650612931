import { Parallax } from "react-scroll-parallax";
import "./CenterText.scss";
import { motion } from "framer-motion";

const CenterText = ({ data }) => {
  const pills = [
    "Motley",
    "Crew",
    "Digital",
    "Marketing",
    "SEO",
    "Universe",
    "Meta",
    "WebDev",
  ];

  const xD = [-80, 250, -190, 120, -380, 0, 360, -30];
  const rD = [-360, 360, 360, -360, 360, 360, -360];

  const lapXd = [-56, 0, 16, -90, -168, 100, 120, -180];

  const mobileXd = [-120, 0, -48, -60, -48, 60, 56, -130];

  return (
    <section className="center_text p_b">
      <div className="content_wrap">
        <h4>{data?.section_1_title}</h4>
      </div>
      {/* For Width > 950px */}
      <div className="pill_sec">
        {data?.services?.slice(0, 8)?.map((pill, j) => (
          <motion.p
            initial={{ translateX: -60, translateY: 0, rotate: 0 }}
            whileInView={{ translateX: xD[j], rotate: rD[j], translateY: 246 }}
            transition={{ duration: 1.5, ease: "easeOut", delay: 0.2 }}
            key={pill?._id}
          >
            {pill?.value}
          </motion.p>
        ))}
      </div>

      {/* For Width <= 950px */}
      <div className="laptop_pill_sec">
        {data?.services?.slice(0, 8)?.map((pill, j) => (
          <motion.p
            initial={{ translateX: -60, translateY: 0, rotate: 0 }}
            whileInView={{
              translateX: lapXd[j],
              rotate: rD[j],
              translateY: j % 2 ? 252 : 206,
            }}
            transition={{ duration: 1.5, ease: "easeOut", delay: 0.2 }}
            key={pill?._id}
          >
            {pill?.value}
          </motion.p>
        ))}
      </div>

      {/* For Width $mobile */}
      <div className="mobile_pill_sec">
        {data?.services?.slice(0, 8)?.map((pill, j) => (
          <motion.p
            initial={{ translateX: -60, translateY: 0, rotate: 0 }}
            whileInView={{
              translateX: mobileXd[j],
              rotate: rD[j],
              translateY: j % 2 ? 252 : j === 4 ? 156 : 204,
            }}
            transition={{ duration: 1.5, ease: "easeOut", delay: 0.2 }}
            key={pill?._id}
          >
            {pill?.value}
          </motion.p>
        ))}
      </div>
    </section>
  );
};

export default CenterText;
