import "./Expertise.scss";
import DotTitle from "../../../components/dotTitle/DotTitle";
import SquareBtn from "../../../components/squareBtn/SquareBtn";
import { motion } from "framer-motion";
import useScrollPosition from "../../../utils/hooks/useScroll";
import { useEffect, useRef, useState } from "react";
import useViewportHeight from "../../../utils/hooks/useViewportHeight";
import { baseUrl } from "../../../utils/apiData";
import { useNavigate } from "react-router-dom";

const Expertise = ({ dataList, title, lTitle, rPara }) => {
  const targetRef = useRef(null);
  const [distanceFromTop, setDistanceFromTop] = useState(0);

  useEffect(() => {
    const section = targetRef.current;
    if (section) {
      const handleScroll = () => {
        const rect = section.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        setDistanceFromTop(rect.top + scrollTop);
      };

      window.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial call to set the distance on load

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const scroll = useScrollPosition();

  const [sectionHeight, setSectionHeight] = useState(0);

  useEffect(() => {
    const section = targetRef.current;
    if (section) {
      const { height } = section.getBoundingClientRect();
      setSectionHeight(height);
    }
  }, []);

  const vh = useViewportHeight();
  const navigate = useNavigate();
  return (
    <section
      ref={targetRef}
      className={`expertise ${
        scroll > distanceFromTop - vh + 300 ? "dark" : "light"
      } p_t p_b`}

    >
      <div className="content_wrap">
        <div className="info_grid">
          <div className="title_wrap">
            <DotTitle title={title} />
          </div>
          <div className="content_wraper">
            <div className="title_right">
              <h2>{lTitle}</h2>
              <div className="sub_title">
                <p>{rPara}</p>
                <SquareBtn
                  theme="light"
                  title="Talk to Us"
                  onClick={() => navigate("contact")}
                />
              </div>
            </div>

            {dataList?.map((data, j) => {
              return (
                <motion.div
                  initial={{ translateY: 24, opacity: 0 }}
                  whileInView={{ translateY: 0, opacity: 1 }}
                  transition={{
                    duration: 0.6,
                    ease: "easeInOut",
                    delay: 0.1 * j,
                  }}
                  viewport={{ once: true }}
                  className="list_card"
                  key={data._id}
                >
                  <div className="img_wrap">
                    <img src={`${baseUrl}/${data?.image}`} alt="Expertise" />
                  </div>
                  <div className="text_content">
                    <h1>{data?.name || "-"}</h1>
                    <div
                      dangerouslySetInnerHTML={{ __html: data?.description }}
                    />
                  </div>
                </motion.div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Expertise;
