import axios from "axios";
import { createContext, useState } from "react";
import { baseUrl, getCta } from "../utils/apiData";

export const dataContext = createContext();

const WebContext = ({ children }) => {
  const [cta, setCta] = useState();

  const getCtaApi = async (page) => {
    try {
      const res = await axios.post(`${baseUrl}/${getCta}`, {
        section: `${page}`,
      });
      if (res?.data?.success) {
        setCta(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const data = {
    getCtaApi,
    cta,
  };

  return <dataContext.Provider value={data}>{children}</dataContext.Provider>;
};

export default WebContext;
