import "./Header.scss";
import { images } from "../../utils/images";
import { NavLink } from "react-router-dom";
import { RiMenu5Line } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { Fragment, useState } from "react";
import useScrollPosition from "../../utils/hooks/useScroll";
import { motion } from "framer-motion";
import { HiOutlineArrowUp } from "react-icons/hi";
import {paths} from "../../utils/paths";

const Header = () => {
  const [dd, setDd] = useState(false);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scroll = useScrollPosition();

  const routing = [
    {
      id: 0,
      text: "Home",
      path: paths.HOME,
    },
    {
      id: 1,
      text: "About",
      path: paths.ABOUT,
    },
    {
      id: 2,
      text: "Work",
      path: paths.WORK,
    },
    {
      id: 3,
      text: "Contact",
      path: paths.CONTACT,
    },
    {
      id: 4,
      text: "Journal",
      path: paths.JOURNAL,
    },
  ];

  return (
    <header>
      <section className="header">
        <div className="content_wrap">
          {scroll <= 10 && (
            <a href="/" className="logo_left">
              <img src={images.logo} alt="Brand Logo" />
            </a>
          )}

          <div
            className="tab_wrap"
            style={
              scroll > 10
                ? { left: "50%", transform: "translateX(-50%)" }
                : { left: "100%", transform: "translateX(-100%)" }
            }
          >
            {scroll > 10 && (
              <motion.a
                initial={{ scale: 0, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.2, ease: "easeInOut" }}
                viewport={{ once: true }}
                href="/"
                className="logo_wrap"
              >
                <img
                  src={images.favIcon}
                  alt="Brand Logo"
                  style={{ transform: `rotate(${scroll / 3}deg)` }}
                />
              </motion.a>
            )}

            {routing.map((data) => (
              <Fragment key={data.id}>
                <NavLink to={data.path} className="link">
                  {data.text}
                </NavLink>
              </Fragment>
            ))}

            <motion.button
              initial={{ scale: 0, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
              viewport={{ once: true }}
              type="button"
              className="menu"
              onClick={() => setDd((prev) => !prev)}
            >
              {dd ? <IoMdClose /> : <RiMenu5Line />}
            </motion.button>

            {scroll > 10 && (
              <motion.button
                initial={{ scale: 0, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.2, ease: "easeInOut" }}
                viewport={{ once: true }}
                type="button"
                onClick={goToTop}
              >
                <HiOutlineArrowUp />
              </motion.button>
            )}
          </div>
        </div>
      </section>

      <div className={`header_dd ${dd ? "open" : "close"}`}>
        <div className="link_line">
          {routing.map((data) => (
            <Fragment key={data.id}>
              <NavLink to={data.path}>{data.text}</NavLink>
            </Fragment>
          ))}
        </div>
      </div>
    </header>
  );
};

export default Header;
