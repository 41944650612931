import "./ContactForm.scss";
import { Fragment, useRef, useState } from "react";
import { motion } from "framer-motion";
import SquareBtn from "../../../components/squareBtn/SquareBtn";
import { MdOutlineClose } from "react-icons/md";
import axios from "axios";
import { baseUrl, contact } from "../../../utils/apiData";

import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const [captcha, setCaptcha] = useState("");
  const recaptchaRef = useRef();

  const category = [
    {
      id: 2,
      title: "Consulting",
    },
    {
      id: 3,
      title: "Branding",
    },
    {
      id: 4,
      title: "Design",
    },
    {
      id: 5,
      title: "Marketing",
    },
    {
      id: 6,
      title: "Ecosystem",
    },
  ];
  const interestList = [
    {
      id: 1,
      title: "DIY/ Self service solutions",
    },
    {
      id: 2,
      title: "Execution Support",
    },
  ];

  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [curious, setCurious] = useState([]);
  const [interest, setInterest] = useState([]);

  const selectHandler = (title) => {
    if (curious.includes(title)) {
      setCurious((prev) => prev.filter((el) => el !== title));
    } else {
      setCurious((prev) => [...prev, title]);
    }
  };

  const interestHandler = (title) => {
    if (interest.includes(title)) {
      setInterest((prev) => prev.filter((el) => el !== title));
    } else {
      setInterest((prev) => [...prev, title]);
    }
  };

  const [contactInfo, setContactInfo] = useState({
    name: "",
    company: "",
    email: "",
    message: "",
  });

  const inputHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setContactInfo((values) => ({ ...values, [name]: value }));
    validator(name, value);
  };

  const blurHandler = (e) => {
    const { name, value } = e.target;
    validator(name, value);
  };

  const validator = (name, value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (name === "name") {
      if (value.trim().length === 0) {
        setNameError("This is a required field.");
      } else {
        setNameError("");
      }
    }
    if (name === "email") {
      if (value.trim().length === 0) {
        setEmailError("This is a required field.");
      } else if (!emailPattern.test(value)) {
        setEmailError("Invalid email format!");
      } else {
        setEmailError("");
      }
    }
  };

  const resetForm = () => {
    setContactInfo({
      name: "",
      company: "",
      email: "",
      message: "",
    });
    setCurious([]);
    setInterest([]);
  };

  // Contact Form API
  const contactApi = async (body) => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${contact}`, { ...body });
      if (res?.data?.success) {
        setSuccess(true);
        resetForm();
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const submitForm = () => {
    contactApi({
      ...contactInfo,
      about: curious,
      intrested_in: interest,
    });
  };

  return (
    <section className="contact_sec p_top">
      <div className="content_wrap">
        <div className="title_wrap">
          <h1>Contact</h1>
          <p>We love hearing from brilliant people with big ideas.</p>
        </div>
        {success ? (
          <div className="success_screen">
            <button
              className="close"
              onClick={() => {
                setSuccess(false);
                setCaptcha("");
              }}
            >
              <MdOutlineClose />
            </button>
            <h3>Thanks...</h3>
            <p>
              Thank you for reaching out! Our team will get back to you soon.
            </p>
          </div>
        ) : (
          <Fragment>
            <div className="form_line">
              <h5>Hey! My name is</h5>
              <div className="mandetory_field">
                <input
                  type="text"
                  placeholder="Your name"
                  name="name"
                  value={contactInfo.name}
                  onChange={inputHandler}
                  onBlur={blurHandler}
                />
                {nameError && <p className="error">{nameError}</p>}
              </div>
              <h5>from</h5>
              <input
                type="text"
                placeholder="Your company"
                name="company"
                value={contactInfo.company}
                onChange={inputHandler}
              />
            </div>

            <h5>I'm exploring:</h5>
            <div className="category_wrap">
              {category.map((data, j) => {
                return (
                  <Fragment key={data.id}>
                    <motion.button
                      initial={{ translateX: 24, opacity: 0 }}
                      whileInView={{ translateX: 0, opacity: 1 }}
                      transition={{
                        duration: 0.6,
                        ease: "easeInOut",
                        delay: 0.1 * j,
                      }}
                      viewport={{ once: true }}
                      type="button"
                      className={curious.includes(data.title) ? "active" : ""}
                      onClick={() => selectHandler(data.title)}
                    >
                      {data.title}
                    </motion.button>
                  </Fragment>
                );
              })}
            </div>

            <div className="form_line">
              <h5>for</h5>
              <textarea
                type="text"
                placeholder="Project name, Idea or Message"
                name="message"
                value={contactInfo.message}
                onChange={inputHandler}
              />
            </div>

            <h5>I'm Interested in:</h5>
            <div className="category_wrap">
              {interestList.map((data, j) => {
                return (
                  <Fragment key={data.id}>
                    <motion.button
                      initial={{ translateX: 24, opacity: 0 }}
                      whileInView={{ translateX: 0, opacity: 1 }}
                      transition={{
                        duration: 0.6,
                        ease: "easeInOut",
                        delay: 0.1 * j,
                      }}
                      viewport={{ once: true }}
                      type="button"
                      className={interest.includes(data.title) ? "active" : ""}
                      onClick={() => interestHandler(data.title)}
                    >
                      {data.title}
                    </motion.button>
                  </Fragment>
                );
              })}
            </div>

            <div className="form_line">
              <h5>You can reach me at</h5>
              <div className="mandetory_field">
                <input
                  type="text"
                  placeholder="Your email"
                  name="email"
                  value={contactInfo.email}
                  onChange={inputHandler}
                  onBlur={blurHandler}
                />
                {emailError && <p className="error">{emailError}</p>}
              </div>
            </div>

            <div className="captcha_wrap">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Lce45AqAAAAALBM8Mll9oKKHMmdAs7h0VzQfABc"
                onChange={(value) => setCaptcha(value)}
              />
            </div>

            {loader ? (
              <SquareBtn type="button" title="Loading..." />
            ) : (
              <SquareBtn
                type="button"
                title="Submit"
                onClick={submitForm}
                isDisabled={
                  nameError ||
                  emailError ||
                  contactInfo.email?.trim()?.length == 0 ||
                  contactInfo.name?.trim()?.length == 0 ||
                  !captcha
                }
              />
            )}
          </Fragment>
        )}
      </div>
    </section>
  );
};
export default ContactForm;
