import DotTitle from "../../../components/dotTitle/DotTitle";
import "./WhoWe.scss";
import { images } from "../../../utils/images";
import { IoPlay } from "react-icons/io5";
import { motion } from "framer-motion";
import SquareBtn from "../../../components/squareBtn/SquareBtn";
import { useNavigate } from "react-router-dom";

const WhoWe = ({ homeData }) => {
  const goToWork = () => {
    document.getElementById("work").scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const navigate = useNavigate();

  return (
    <section className="who_we p_b">
      <div className="content_wrap">
        <div className="title_sec">
          <div className="blog"></div>

          <div className="title_side">
            <DotTitle title={homeData?.who_title || "-"} />
          </div>
          <div className="right">
            <motion.h3
              initial={{ translateY: 24, opacity: 0 }}
              whileInView={{ translateY: 0, opacity: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              viewport={{ once: true }}
            >
              {homeData?.who_description || "-"}
            </motion.h3>
            <motion.div
              initial={{ translateY: 24, opacity: 0 }}
              whileInView={{ translateY: 0, opacity: 1 }}
              transition={{
                duration: 0.6,
                ease: "easeInOut",
              }}
              viewport={{ once: true }}
              className="btn_line"
            >
              <SquareBtn
                theme="light"
                title="Let’s Find Out"
                onClick={goToWork}
              />
            </motion.div>
          </div>
        </div>
        <div className="how_we_work">
          <div className="project_card">
            <img
              src={images.whoBg}
              alt="Bg"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                height: "100%",
                width: "100%",
                objectFit: "cover",
                zIndex: "0",
              }}
            />

            <div className="count_grid">
              <div className="counter">
                <motion.h1
                  initial={{ translateY: 24, opacity: 0 }}
                  whileInView={{ translateY: 0, opacity: 1 }}
                  transition={{ duration: 0.6, ease: "easeInOut" }}
                  viewport={{ once: true }}
                >
                  {homeData?.project_finished || "-"}
                </motion.h1>
                <motion.p
                  initial={{ translateY: 24, opacity: 0 }}
                  whileInView={{ translateY: 0, opacity: 1 }}
                  transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
                  viewport={{ once: true }}
                >
                  {homeData?.project_finished_title || "-"}
                </motion.p>
              </div>
              <div className="counter">
                <motion.h1
                  initial={{ translateY: 24, opacity: 0 }}
                  whileInView={{ translateY: 0, opacity: 1 }}
                  transition={{ duration: 0.6, ease: "easeInOut" }}
                  viewport={{ once: true }}
                >
                  {homeData?.marketing_communication}
                </motion.h1>
                <motion.p
                  initial={{ translateY: 24, opacity: 0 }}
                  whileInView={{ translateY: 0, opacity: 1 }}
                  transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
                  viewport={{ once: true }}
                >
                  {homeData?.marketing_communication_title}
                </motion.p>
              </div>
            </div>
          </div>
          <div className="title_card">
            <img src={images.journalBg} alt="Bg" className="bg" />
            <div className="over_lay"></div>
            <div className="content">
              <motion.h2
                initial={{ translateY: 24, opacity: 0 }}
                whileInView={{ translateY: 0, opacity: 1 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
                viewport={{ once: true }}
              >
                HOW WE DO IT
              </motion.h2>
              <motion.button
                initial={{ translateY: 24, opacity: 0 }}
                whileInView={{ translateY: 0, opacity: 1 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
                viewport={{ once: true }}
                type="button"
                onClick={() => navigate("/journal")}
              >
                <span>
                  <IoPlay />
                  <img src={images.podcaster} alt="avtar" />
                </span>
                <div className="text">
                  <p className="dark">Listen to the Podcast</p>
                  <p>Damn Good Marketing</p>
                </div>
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWe;
