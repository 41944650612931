import "./Values.scss";
import SecTitle from "../../../components/secTitle/SecTitle";
import { Parallax } from "react-scroll-parallax";

const Values = ({data}) => {
  const valueA = {
    title:data?.sec_1_title,
    para:data?.sec_1_description
  };

  const valueB = {
    title:data?.sec_2_title,
    para:data?.sec_2_description
  };

  const valueC = {
    title:data?.sec_3_title,
    para:data?.sec_3_description
  };

  return (
    <section className="values p_t" id="values">
      <div className="content_wrap">
        <SecTitle title="Values" mainTitle={data?.value_title} />

        {/* For Screen > 812 ($tab) */}
        <div className="tri_grid">
          <div className="info">
            <h3>{data?.value_heading || "-"}</h3>
            <p>{data?.value_description || "-"}</p>
          </div>
          <Parallax translateY={[-20, 20]}>
            <div className="sec" style={{ transform: "translateY(100px)" }}>
              <div className="dummy_card"></div>
              <div className="value_card">
                <h3>{valueA?.title}</h3>
                <p>{valueA?.para}</p>
              </div>
              <div className="dummy_card"></div>
              <div className="dummy_card"></div>
            </div>
          </Parallax>

          <Parallax translateY={[20, -20]}>
            <div className="sec" style={{ transform: "translateY(-100px)" }}>
              <div className="dummy_card"></div>
              <div className="value_card">
                <h3>{valueB?.title}</h3>
                <p>{valueB?.para}</p>
              </div>

              <div className="value_card">
                <h3>{valueC?.title}</h3>
                <p>{valueC?.para}</p>
              </div>
              <div className="dummy_card"></div>
              <div className="dummy_card"></div>
            </div>
          </Parallax>
        </div>

        {/* For Screen <= 812 ($tab) */}
        <div className="tab_sec">
          <Parallax translateX={[-10, 10]}>
            <div className="h_grid">
              <div className="dummy_card"></div>
              <div className="value_card">
                <h3>{valueA?.title}</h3>
                <p>{valueA?.para}</p>
              </div>
              <div className="dummy_card"></div>
            </div>
          </Parallax>

          <Parallax translateX={[10, -10]}>
            <div className="h_grid">
              <div className="dummy_card"></div>
              <div className="value_card">
                <h3>{valueB?.title}</h3>
                <p>{valueB?.para}</p>
              </div>
              <div className="dummy_card"></div>
            </div>
          </Parallax>

          <Parallax translateX={[-10, 10]}>
            <div className="h_grid">
              <div className="dummy_card"></div>
              <div className="value_card">
                <h3>{valueC?.title}</h3>
                <p>{valueC?.para}</p>
              </div>
              <div className="dummy_card"></div>
            </div>
          </Parallax>
        </div>
      </div>
    </section>
  );
};

export default Values;
