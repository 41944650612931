import SecTitle from "../../../components/secTitle/SecTitle";
import SquareBtn from "../../../components/squareBtn/SquareBtn";
import "./CaseSummary.scss";
import { motion } from "framer-motion";

const CaseSummary = ({ data }) => {
  return (
    <section className="case_summary p_b">
      <div className="content_wrap">
        <SecTitle title="Summary" mainTitle={data?.section_6_title || "-"} />
        <div className="bi_grid">
          <div className="count_grid">
            {data?.project_duration_1 && data?.project_title_1 && (
              <div className="count">
                <motion.h2
                  initial={{ translateY: 48, opacity: 0 }}
                  whileInView={{ translateY: 0, opacity: 1 }}
                  transition={{ duration: 0.6, ease: "easeInOut" }}
                  viewport={{ once: true }}
                >
                  {data?.project_duration_1}
                </motion.h2>
                <motion.h5
                  initial={{ translateY: 48, opacity: 0 }}
                  whileInView={{ translateY: 0, opacity: 1 }}
                  transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
                  viewport={{ once: true }}
                >
                  {data?.project_title_1}
                </motion.h5>
              </div>
            )}

            {data?.project_duration_2 && data?.project_title_2 && (
              <div className="count">
                <motion.h2
                  initial={{ translateY: 48, opacity: 0 }}
                  whileInView={{ translateY: 0, opacity: 1 }}
                  transition={{ duration: 0.6, ease: "easeInOut" }}
                  viewport={{ once: true }}
                >
                  {data?.project_duration_2}
                </motion.h2>
               
                <motion.h5
                  initial={{ translateY: 48, opacity: 0 }}
                  whileInView={{ translateY: 0, opacity: 1 }}
                  transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
                  viewport={{ once: true }}
                >
                  {data?.project_title_2}
                </motion.h5>
              </div>
            )}
          </div>
          <div className="right">
          <motion.div 
         initial={{ translateY: 24, opacity: 0 }}
         whileInView={{ translateY: 0, opacity: 1 }}
         transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
         viewport={{ once: true }}
         dangerouslySetInnerHTML={{ __html: data?.section_6_description }}
         ></motion.div>
            {data?.website && (
              <a href={data?.website} target="_blank">
                <SquareBtn title="View Website" />
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CaseSummary;
