import DotTitle from "../../../components/dotTitle/DotTitle";
import "./CaseStudies.scss";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl, work } from "../../../utils/apiData";
import { Fragment, useEffect, useState } from "react";
import Loader from "../../../components/loader/Loader";
import CaseStudyCard from "../../work/workList/caseStudyCard/CaseStudyCard";

const CaseStudies = ({ homeData }) => {
  const [caseList, setCaseList] = useState([]);
  const [loader, setLoader] = useState(false);


  // getCaseList Api
  const getCaseListApi = async () => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${work}`);
      if (res?.data?.success) {
        setCaseList( res?.data?.data?.blogsdata);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getCaseListApi();
  }, []);

  return (
    <section className="case_studies p_b" id="work">
      <div className="content_wrap">
        <div className="title_wrap">
          <DotTitle title={homeData?.work_title || "-"} />
          <motion.h2
            initial={{ translateY: 24, opacity: 0 }}
            whileInView={{ translateY: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            viewport={{ once: true }}
          >
            {homeData?.work_heading || "-"}
          </motion.h2>
        </div>

        {loader && <Loader />}

        {!loader && caseList?.length > 0 ? (
          caseList?.slice(0, 3)?.map((data) => {
            return (
              <Fragment key={data?._id}>
                <CaseStudyCard data={data} />
              </Fragment>
            );
          })
        ) : (
          <p className="text-center">Data not found...</p>
        )}
      </div>
    </section>
  );
};

export default CaseStudies;
