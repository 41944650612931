import logo from "../assets/images/logo.png";
import favIcon from "../assets/images/favicon.png";

import whoBg from "../assets/images/home/whobg.jpg";
import journalBg from "../assets/images/home/journal_bg.webp";
import podcaster from "../assets/images/home/podcaster.webp";
import ctaBg from "../assets/images/home/ctabg.jpg";
import expertA from "../assets/images/home/expertA.avif";
import aboutLanding from "../assets/images/about/aboutlanding.avif";

export const images = {
  logo,
  favIcon,

  whoBg,
  journalBg,
  podcaster,

  ctaBg,
  expertA,

  aboutLanding,
};
