import "./SquareBtn.scss";
import { HiOutlineArrowSmRight } from "react-icons/hi";

const SquareBtn = ({ title, theme, type, onClick, isDisabled }) => {
  return (
    <button
      type={type ? type : "button"}
      className={`square_btn ${theme}`}
      onClick={onClick}
     disabled={isDisabled}
    >
      <span className="dark"></span>
      <span className="light"></span>
      <HiOutlineArrowSmRight />
      <span className="title">{title}</span>
    </button>
  );
};

export default SquareBtn;
