import WebLayout from "../../layouts/weblayout/WebLayout";
import ContactForm from "./contactForm/ContactForm";

const Contact = () => {
  return (
    <WebLayout page="contact">
      <ContactForm />
    </WebLayout>
  );
};

export default Contact;
