import WebLayout from "../../layouts/weblayout/WebLayout";
import WorkList from "./workList/WorkList";

const Work = () => {
  return (
    <WebLayout page="work" isCta={true}>
      <WorkList />
    </WebLayout>
  );
};

export default Work;
