import SquareBtn from "../../../../components/squareBtn/SquareBtn";
import { motion } from "framer-motion";
import { baseUrl } from "../../../../utils/apiData";
import { useNavigate } from "react-router-dom";

const CaseStudyCard = ({ data }) => {
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  return (
    <div className="case_sec">
      <div className="text">
        <div className="case_info">
          <motion.h5
            initial={{ translateY: 24, opacity: 0 }}
            whileInView={{ translateY: 0, opacity: 1 }}
            transition={{
              duration: 0.6,
              ease: "easeInOut",
              delay: 0.1,
            }}
            viewport={{ once: true }}
          >
            {data?.name || "-"}
          </motion.h5>
          <motion.p
            initial={{ translateY: 24, opacity: 0 }}
            whileInView={{ translateY: 0, opacity: 1 }}
            transition={{
              duration: 0.6,
              ease: "easeInOut",
              delay: 0.2,
            }}
            viewport={{ once: true }}
          >
            {data?.subtitle || "-"}
          </motion.p>
          <motion.p
            initial={{ translateY: 24, opacity: 0 }}
            whileInView={{ translateY: 0, opacity: 1 }}
            transition={{
              duration: 0.6,
              ease: "easeInOut",
              delay: 0.3,
            }}
            viewport={{ once: true }}
            className="date"
          >
            {formatDate(data?.publish_date)}
          </motion.p>
        </div>
        <motion.div
          initial={{ translateY: 24, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{
            duration: 0.6,
            ease: "easeInOut",
          }}
          viewport={{ once: true }}
        >
          <SquareBtn
            title="About Project"
            onClick={() => navigate(`/casestudy/${data?.slug}`)}
          />
        </motion.div>
      </div>
      <motion.div
        className="img_wrap"
        initial={{ translateX: 48, opacity: 0 }}
        whileInView={{ translateX: 0, opacity: 1 }}
        transition={{ duration: 0.6, ease: "easeInOut" }}
        viewport={{ once: true }}
      >
        <img src={`${baseUrl}/${data?.image}`} alt="Case Study" />
        <div className="pill_wrap">
          {data?.tags?.map((pill) => {
            return (
              <div className="pill" key={pill?._id}>
                <p>{pill?.value}</p>
              </div>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
};

export default CaseStudyCard;
